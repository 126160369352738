<template>
    <div
        class="settings-page dF fC pb-5"
        :class="$mq == 'sm' ? 'px-4' : 'container container-2'"
    >
        <div class="mb-3" style="font-size: 18px">
            <a-icon type="arrow-left" class="mr-3" @click="$router.go(-1)" />
            <b>{{instance.name}}</b>
        </div>
        <bh-loading :show="loading" />
        <a-card>
            <a-tabs default-active-key="details" size="large">
                <a-tab-pane key="details" tab="Project Details">
                    <a-form-model ref="detailsForm" :model="instance">
                        <div :class="$mq == 'sm' ? '' : 'dF'">
                            <div
                                style="order: 2"
                                :style="$mq == 'sm' ? 'width:100%' : 'width:25%'"
                            >
                                <div class="hexagon-main">
                                    <div class="hexagon hexagon2">
                                        <div class="hexagon-in1">
                                            <div
                                                @click="
                                                    colourInstance(instance)
                                                "
                                                class="hexagon-in2"
                                                :style="{
                                                    'background-color':
                                                        getBackground(instance),
                                                }"
                                            ></div>
                                        </div>
                                    </div>
									<div class="text-center">
										<strong>{{ instance.readableId || '' }}</strong>
										<br/>
                                        <strong>{{ instance.name }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="$mq == 'sm' ? 'mt-3' : ''"
                                :style="$mq == 'sm' ? 'width:100%' : 'width:75%'"
                            >
								<a-row :gutter="16">
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="Project ID"
                                        >
                                            <a-input
                                                :value="instance.readableId"
                                                placeholder="Project ID"
                                                size="large"
                                                disabled
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="Project Name"
                                            prop="name"
											:rules="req('Please enter the project name')"
                                        >
                                            <a-input
                                                v-model="instance.name"
                                                placeholder="Project Name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
									<a-col :span="8">
                                        <a-form-model-item
                                            prop="marketingName"
                                        >
											<template slot="label">
												Project Marketing Name
												<a-tooltip overlayClassName="change-tooltip-color" title="Fill in this field if you need a forward-facing name that end users will see for your project.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
                                            <a-input
                                                v-model="instance.marketingName"
                                                placeholder="Project Marketing Name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
									<a-col :span="8" v-if="instance.partner">
                                        <a-form-model-item label="Partner" >
                                            <a-input
                                                :value="instance.partner.companyName"
                                                placeholder="Partner"
                                                size="large"
                                                disabled
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
								<a-row :gutter="16">
	                                <a-col :span="8">
	                                    <a-form-model-item
	                                        ref="projectType"
	                                        label="Project Type"
	                                        prop="projectType"
	                                    >
	                                        <a-input
	                                            :value="instance.projectType"
	                                            placeholder="Project Type"
	                                            size="large"
	                                            disabled
	                                        >
	                                        </a-input>
	                                    </a-form-model-item>
	                                </a-col>
	                                <a-col :span="8">
	                                    <a-form-model-item
	                                        ref="productType"
	                                        label="Product Type"
	                                        prop="productType"
	                                    >
	                                        <a-input
	                                            :value="
	                                                instance.productType ===
	                                                'lowrise'
	                                                    ? 'Low Rise'
	                                                    : 'High Rise'
	                                            "
	                                            placeholder="Product Type"
	                                            size="large"
	                                            disabled
	                                        >
	                                        </a-input>
	                                    </a-form-model-item>
	                                </a-col>
	                                <a-col :span="8">
	                                    <a-form-model-item
	                                        ref="status"
	                                        label="Project Status"
	                                        prop="status"
	                                    >
	                                        <a-input
	                                            :value="projectStatus"
	                                            placeholder="Project Status"
	                                            size="large"
	                                            disabled
	                                        >
	                                        </a-input>
	                                    </a-form-model-item>
	                                </a-col>
	                            </a-row>
								<a-row :gutter="16">
									<a-col :span="8">
                                        <a-form-model-item
                                            required
                                            prop="billingName"
                                            :rules="req('Please enter the Billing Name')"
                                        >
											<template slot="label">
												Billing Name
												<a-tooltip overlayClassName="change-tooltip-color" title="The 'Billing Name' is what will appear on the invoice.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
                                            <a-input
                                                v-model="instance.billingName"
                                                placeholder="Billing Name"
                                                size="large"
                                                required
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="Address"
                                            prop="address"
											:rules="req('Please enter the address')"
                                        >
                                            <a-input
                                                v-model="instance.address"
                                                placeholder="Address"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            label="City"
                                            prop="city"
											:rules="req('Please enter the city name')"
                                        >
                                            <a-input
                                                v-model="instance.city"
                                                placeholder="Enter a city name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="16">
                                    <a-col :span="8">
                                        <a-form-model-item
                                            prop="region"
                                            label="Province/State"
											:rules="req('Please enter the Province/State name')"
                                        >
                                            <a-input
                                                v-model="instance.region"
                                                placeholder="Enter a Province/State name"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            prop="postalCode"
                                            label="Zip/Postal Code"
											:rules="req('Please enter the Zip/Postal Code')"
                                        >
                                            <a-input
                                                v-model="instance.postalCode"
                                                placeholder="Enter a Postal Code"
                                                size="large"
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-model-item
                                            prop="country"
                                            label="Country"
											:rules="req('Please select country')"
                                        >
                                            <a-select
                                                v-model="instance.country"
                                                placeholder="Country"
                                                size="large"
                                            >
                                                <a-select-option value="US"
                                                    >United
                                                    States</a-select-option
                                                >
                                                <a-select-option value="CA"
                                                    >Canada</a-select-option
                                                >
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <hr style="margin-left: -24px; margin-right: -24px" />
                                <a-row :gutter="16">
                                    <a-col :span="12">
                                        <a-form-model-item label="Currency">
                                            <a-select
                                                v-model="instance.currency"
        										size="large"
                                            >
                                                <a-select-option value="USD"
                                                    >United States Dollar
                                                    ($)</a-select-option
                                                >
                                                <a-select-option value="CAD"
                                                    >Canadian Dollar
                                                    (CAD)</a-select-option
                                                >
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>

    								<a-col :span="12" v-if="instanceAPIKey">
    									<a-form-model-item>
    										<template slot="label">
    											API Key
    											<a-tooltip placement="right" overlayClassName="change-tooltip-color">
    												<template slot="title">
    													In order to integrate Bildhive on external websites, you will need to use Bildhive's APIs. The Bildhive APIs are protected and require an API key in order to be used. Each project has its own unique API key.
    												</template>
    												<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
    											</a-tooltip>
    										</template>
    										<a-input :value="showAPIKey ? instanceAPIKey : '********************' + instanceAPIKey.substring(
    													20
    												)" size="large" disabled>
    											<div slot="addonBefore" class="dF aC" style="gap: 5px">
    												<a-icon type="copy" @click="copy(instanceAPIKey)" />
    											</div>
    											<div slot="addonAfter" class="dF aC" style="gap: 5px">
    												<a-icon :type="showAPIKey ? 'eye-invisible' : 'eye'" @click="showAPIKey = !showAPIKey" />
    											</div>
    										</a-input>
    									</a-form-model-item>
    								</a-col>
                                </a-row>

                                <a-row
                                    :gutter="16"
                                    v-if="
                                        storeInstances && storeInstances.length
                                    "
                                    class="mb-3"
                                >
                                    <a-col :span="24">
                                        <a-divider orientation="left">Link Other Child Projects</a-divider >
                                        <div class="dF aC" style="gap: 20px">
                                            <div>
                                                Link child projects to the
                                                parent project
                                            </div>
                                            <a-select
                                                mode="multiple"
                                                size="large"
                                                placeholder="Please select"
                                                v-model="children"
                                                style="width: 500px"
												@search="handleInstanceSearch"
												@blur="instanceBlur"
												:filter-option="false"
                                            >
                                                <a-select-option v-for="inst in filteredInstances" :key="inst.id" :value="inst.id">
                                                    {{ inst.name }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                    </a-form-model>
                    <div class="w-full mt-3" v-if="instance.own">
                        <hr style="margin-left: -24px; margin-right: -24px" />

                        <div
                            class="pull-left"
                        >
                            <a-button
                                @click="deleteModal = true"
                                type="danger"
                                ghost size="large"
                                ><a-icon type="delete" />DELETE</a-button
                            >
                        </div>

                        <div class="pull-right">
                            <a-button class="mr-3" @click="onClose" size="large">CANCEL</a-button>
                            <a-button type="primary" @click="onDetailsSave" size="large">SAVE</a-button>
                        </div>
                    </div>
                </a-tab-pane>

                <a-tab-pane key="settings" tab="Project Settings">
                    <a-form-model
                        ref="settingForm"
                        :model="instanceSettings"
                    >
                        <a-divider orientation="left">Measurement Settings</a-divider >
                        <a-row :gutter="16">
                            <a-col
                                v-if="selectedInstance.productType === 'lowrise'"
                                :span="8"
                            >
                                <a-form-model-item>
                                    <div slot="label" class="dF aC">
                                        <div class="mr-1">
                                            Lot Frontage Size
                                        </div>
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                This will allow you to set your
                                                site plan lot frontage to either
                                                feet or metres.
                                            </template>
                                            <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                                        </a-tooltip>
                                    </div>
                                    <a-select
                                        v-model="instanceSettings.measurement.frontageSize"
										size="large"
                                    >
                                        <a-select-option value="ft">Feet (ft)</a-select-option>
                                        <a-select-option value="m">Metre (m)</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item>
                                    <div slot="label" class="dF aC">
                                        <div class="mr-1">
                                            Home and Unit Measurements
                                        </div>
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                This will determine if your
                                                model will be shown as feet or
                                                metres.
                                            </template>
                                            <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                                        </a-tooltip>
                                    </div>
                                    <a-select
                                        v-model="instanceSettings.measurement.generalMeasurement"
										size="large"
                                    >
                                        <a-select-option value="ft">Feet (ft)</a-select-option>
                                        <a-select-option value="m">Metre (m)</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <div v-if="instance.package?.toLowerCase().includes('premium')">
                            <a-divider orientation="left">
                                Activate/Deactivate Feature Settings
                                <a-tooltip overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        By default, the Reservation & Worksheet feature is active. To deactivate it, simply toggle the radio button to the 'off' position.
                                    </template>
                                    <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                                </a-tooltip>
                            </a-divider >
                            <a-row :gutter="16">
                                <a-col :span="8">
                                    <a-form-model-item>
                                        <a-switch v-model="instanceSettings.activeFeatures.reservation" checked-children="on" un-checked-children="off" :disabled="!instance.own"/>
                                        <span class="text-md ml-2">
                                            Activate Reservation Feature
                                        </span>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-form-model-item>
                                        <a-switch v-model="instanceSettings.activeFeatures.worksheet" checked-children="on" un-checked-children="off" :disabled="!instance.own"/>
                                        <span class="text-md ml-2">
                                            Activate Worksheet Feature
                                        </span>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </div>
                    </a-form-model>

                    <div class="w-full mt-3" v-if="instance.own">
                        <hr style="margin-left: -24px; margin-right: -24px" />

                        <div class="pull-right">
                            <a-button class="mr-3" @click="onClose" size="large">CANCEL</a-button>
                            <a-button type="primary" @click="onSettingsSave" size="large">SAVE</a-button>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-card>
        <a-modal
            v-model="deleteModal"
            title="Delete Your Project"
            ok-text="DELETE"
            @ok="onDelete"
            :ok-button-props="{ props: { disabled: !deleteAccepted } }"
        >
            <p>
                You are about to delete your project. All your data will be
                deleted.
            </p>
            <p>
                <b>Consider downloading your data before deleting a project.</b>
            </p>
            <a-checkbox v-model="downloadData">
                <span>
                    DOWNLOAD DATA (Your data will consist of all your leads and
                    all your completed transactions.)
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox an email with a link
                            will be send to you that will allow you to download
                            your data.
                        </template>
                        <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
                    </a-tooltip>
                </span>
            </a-checkbox>
            <br />
            <br />
            <a-checkbox v-model="deleteAccepted">
                I understand that deleting my project can’t be undone and all my
                data will be deleted.
            </a-checkbox>
        </a-modal>
        <a-modal
            v-model="colourModal.visible"
            title="Colors"
            @ok="handleColorChange"
        >
            <div class="dF aC" style="flex-wrap: wrap">
                <div
                    class="px-1 py-1"
                    v-for="(color, colorI) in colourList"
                    :key="color + colorI"
                    :value="color"
                >
                    <div
                        class="relative"
                        @click="selectedColor = color"
                        :style="{ 'background-color': color }"
                        style="
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                        "
                    >
                        <div
                            v-if="selectedColor === color"
                            class="absolute"
                            style="
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <i class="fe fe-check" />
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>


<script>
import bhLoading from "bh-mod/components/common/Loading";

export default {
    components: {
        bhLoading,
    },
    data: function () {
        return {
			filteredInstances: [],
            children: [],
            loading: false,
            deleteModal: false,
            deleteAccepted: false,
            downloadData: false,
			showAPIKey: false,
			instanceAPIKey: "",
            instanceID: "",
            instance: {
                name: "",
				marketingName: "",
                address: "",
                city: "",
                region: "",
                country: "",
                email: "",
                avatar: "",
                postalCode: "",
				currency: "USD"
            },
            instanceSettings: {
                measurement: {
                    frontageSize: "ft",
                    generalMeasurement: "ft",
                },
                activeFeatures: {
                    reservation: true,
                    worksheet: true,
                },
            },
            colourList: [
                "#FEC267",
                "#C1BCF4",
                "#89C6E5",
                "#7CDDC1",
                "#FCAFBA",
                "#3A558A",
                "#CD4386",
                "#E089B8",
                "#EF6D8B",
                "#FFDCE6",
                "#227949",
                "#9CB63E",
                "#C7E77A",
                "#18A1B1",
                "#58CAD5",
                "#F3AC46",
                "#FED762",
                "#0A5A93",
                "#83776B",
                "#BEB7AC",
                "#EEDDBF",
                "#F3F1EC",
                "#AE9898",
                "#5AF8E1",
                "#52C4FD",
                "#AB81FC",
                "#C3C3C5",
                "#FD395D",
                "#ACECF9",
                "#FECCAC",
                "#D18063",
                "#917B56",
                "#FDA28F",
                "#7FFD80",
                "#AAD7CF",
                "#D8ECE2",
                "#CBCFE0",
                "#7F7F7F",
                "#B2B2B2",
                "#E5E5E5",
            ],
            selectedColor: "",
            colourModal: {
                visible: false,
                instance: {},
            },
        };
    },
    watch: {
        colourModal: {
            handler(val) {
                if (val.visible) {
                    const color = this.getBackground(val.instance);
                    if (color !== "") {
                        this.selectedColor = color;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
		projectStatus() {
            if (this.instance.deleted) return "Deleted";
            if (this.instance.susspended) return "Locked";
            return "Active";
        },

        instances() {
            let instances = this.$store.state.instances;
            instances = instances.filter((instance) => {
                return (
                    instance.role &&
                    instance.role.name === "bh_admin" &&
                    instance.id !== this.selectedInstance.id
                );
            });
            if (
                this.selectedInstance.id === this.$store.state.childrenList.instance &&
                this.$store.state.childrenList.children.length
            ) {
                let list = this.$store.state.childrenList.children;
                list.forEach((instance) => {
                    const find = instances.find((i) => i.id === instance.id);
                    if (!find) {
                        instances.push(instance);
                    }
                });
            }
            return instances;
        },
        selectedInstance() {
            return this.$store.state.editDrawer.instance;
        },
        storeInstances() {
            return this.$store.state.instances;
        },
        user() {
            return this.$store.state.user.user || {};
        },
        userSettings() {
            if (
                this.$store.state.user.user.settings != null &&
                Object.keys(this.$store.state.user.user.settings).length != 0
            ) {
                return this.$store.state.user.user.settings;
            } else return {};
        },
    },
    methods: {
		req: (msg) => ({ required: true, message: msg }),

		instanceBlur(){
			this.filteredInstances = this.instances
		},
		handleInstanceSearch(value){
			if (value && value.length == 0){
				this.filteredInstances = this.instances
			} else {
				const inputValue = value.toLowerCase();

				this.filteredInstances = this.instances.filter(inst =>
					inst.name.toLowerCase().includes(inputValue)
				);
			}
		},
        colourInstance(instance) {
            this.colourModal.instance = instance;
            this.colourModal.visible = true;
        },

        handleColorChange() {
            if (this.selectedColor != "") {
                let options = {};
                if (
                    this.userSettings.options &&
                    Object.keys(this.userSettings.options).length != 0 &&
                    this.userSettings != null &&
                    Object.keys(this.userSettings).length != 0
                ) {
                    let storeOptions = JSON.parse(
                        JSON.stringify(this.userSettings.options)
                    );
                    Object.keys(storeOptions).forEach((key) => {
                        options[key] = storeOptions[key];
                    });
                    if (!options.colour) {
                        let colour = {};
                        options.colour = colour;
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    } else {
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    }
                } else {
                    let colour = {};
                    options.colour = colour;
                    options.colour[this.colourModal.instance.id] =
                        this.selectedColor;
                }
                this.$api
                    .post("/settings", { options: options })
                    .then(({ data }) => {
                        let user = JSON.parse(JSON.stringify(this.user));
                        user.settings = data;
                        this.$store.commit("UPDATE_USER", user);
                        this.colourModal.visible = false;
                        this.colourModal.instance = {};
                        this.selectedColor = "";
                    }).catch((err) => console.error(err));
            }
        },

        getBackground(instance) {
            const { avatar, id } = instance;
            const found = this.storeInstances.find((x) => x.id === id);
            if (!found) return "";

            const userColor = this.userSettings.options?.colour?.[id];
            if (found.color) {
                return userColor || found.color;
            } else {
                return userColor || avatar || "#FEC267";
            }
        },
        onDetailsSave() {
            this.$refs.detailsForm.validate(async(valid) => {
                if (valid) {
                    const payload = JSON.parse(JSON.stringify(this.instance));
                    payload.avatar = this.instance.avatar;
                    if (this.children.length !== 0) {
                        payload.children = this.children;
                    } else {
                        payload.children = [];
                    }

                    this.loading = true;

                    try {
                        const { data } = await this.$api.put(`/instances/${this.instanceID}`, payload)
                        this.$message.success("Project details updated successfully.");
                        if (data.children?.length !== 0) {
                            let childrenId = [];
                            data.children.forEach((child) => childrenId.push(child.id));
                            data.children = childrenId;
                        }
                        this.$store.commit("CLOSE_EDIT_INSTANCE", data);
                        this.$router.push("/settings/projects");
                    } catch (err) {
                        this.$toastError(err, 'Error while updating project details. Please try again')
                    }

                    this.loading = false
                }
            });
        },

        onSettingsSave() {
            this.$refs.settingForm.validate(async(valid) => {
                if (valid) {
					this.loading = true;
                    let payload = JSON.parse(JSON.stringify(this.instanceSettings));
                    try {
                        await this.$api.post(`/settings/${this.instanceID}`, { options: payload})
                        this.$message.success("Project settings updated successfully.");
                    } catch (err) {
                        this.$toastError(err, 'Error while updating project setting. Please try again')
                    }

                    this.loading = false;
                }
            });
        },

        onClose() {
            this.$store.commit("CLOSE_EDIT_INSTANCE", "");
            this.$router.push("/settings/projects");
        },

        async onDelete() {
            try {
                await this.$api.delete(`/instances/${this.instance.id}?downloadData=${this.downloadData}`);

                for (let i = 0; i < this.instances.length; i++) {
                    if (this.instances[i].id === this.instance.id) {
                        this.instances.splice(i, 1);
                        break;
                    }
                }
                this.deleteModal = false;
                this.$router.push("/settings/projects");
            } catch (err) {
                this.$toastError(err, 'Error while deleting project. Please try again');
            }
        },

		copy(value) {
			navigator.clipboard.writeText(value);
			this.$message.success("Copied");
		},

        async fetchProjectDetails() {
            if (!this.selectedInstance.id) return this.$router.push("/settings/projects");
            try {
                this.loading = true;
                const { data } = await this.$api.get(`/instances/${this.selectedInstance.id}`);

                this.$store.commit("SET_CHILDRENLIST", data);
                this.filteredInstances = this.instances;
                this.instance = {
                    ...data,
                    own: data.billingOwner === this.user.id || data.owner === this.user.id,
                };
                this.instanceAPIKey = data.api?.key || null;
                this.instanceID = this.selectedInstance.id;
                this.children = this.selectedInstance.children?.map(child => child.id) || [];

                if (!data.currency) {
                    this.instance.currency = 'USD';

                    const payload = { ...this.instance, avatar: this.instance.avatar, children: this.children };
                    await this.$api.put(`/instances/${this.instanceID}`, payload);
                }
            } catch (err) {
                this.$toastError(err, 'Error while fetching project details. Please try again');
            } finally {
                this.loading = false;
            }
        },

        async fetchProjectSettings() {
            if (!this.selectedInstance.id) return;
            try {
                this.loading = true;
                const settingsResponse = await this.$api.get(`/settings/${this.selectedInstance.id}`);
                const result = settingsResponse.data || {};
                const options = result.instance?.options || {};

                this.instanceSettings = {
                    ...options,
                    measurement: {
                        frontageSize: options.measurement?.frontageSize || "ft",
                        generalMeasurement: options.measurement?.generalMeasurement || "ft",
                    },
                    activeFeatures: {
                        reservation: options.activeFeatures?.reservation ?? true,
                        worksheet: options.activeFeatures?.worksheet ?? true,
                    }
                }
            } catch (error) {
                this.$toastError(err, 'Error while fetching project settings. Please try again');
            } finally {
                this.loading = false;
            }
        }
    },
    created() {
        this.fetchProjectDetails();
        this.fetchProjectSettings();
	},
};
</script>

<style scoped>
.inherit-selection .ant-form-item-control-wrapper {
    flex: 1;
    margin-left: 15px;
}

.hexagon-main {
    position: relative;
    width: 120px;
    height: 120px;
    display: block;
    margin: auto;
    padding-left: 20px;
}

.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #d0c9d6;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon2 {
    width: 100px;
    height: 200px;
}

.inherit-selection .ant-form-item-control-wrapper {
    flex: 1;
    margin-left: 15px;
}
</style>

<style>
.settings-page .ant-card-body {
    padding: 10px 20px;
}
</style>
